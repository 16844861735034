import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { renderContent } from '@quantore/gatsby-theme-quantore/src/utils';
import Breadcrumb from '@quantore/gatsby-theme-quantore/src/components/Breadcrumb/Breadcrumb';
import StoresList from '@quantore/gatsby-theme-quantore/src/components/StoresList/StoresList';

import { PageContainer, ContentContainer, PageTitle, Content } from './styles';

const StoresRegionPage = ({ pageContext, location, data }) => {
  const stores = data.stores.edges.map((store) => store.node);
  const {
    metaTagTitle,
    metaTagDescription,
    metaTagRobots,
    breadcrumbs,
    title,
    richDescription,
  } = data.region;

  return (
    <>
      <Helmet>
        <title>{metaTagTitle ? metaTagTitle : title}</title>
        <link rel="canonical" href={location.href} />
        <meta
          name="robots"
          content={metaTagRobots ? metaTagRobots : 'index, follow'}
        />
        <meta property="og:url" content={location.href} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={metaTagTitle ? metaTagTitle : title}
        />
        {metaTagDescription && (
          <meta name="description" content={metaTagDescription} />
        )}
        {metaTagDescription && (
          <meta property="og:description" content={metaTagDescription} />
        )}
      </Helmet>

      {breadcrumbs && <Breadcrumb breadcrumb={pageContext.breadcrumb} />}

      <ContentContainer size="s">
        {title && <PageTitle>{title}</PageTitle>}

        {richDescription && richDescription.raw && (
          <Content margin="true">{renderContent(richDescription)}</Content>
        )}
      </ContentContainer>

      <main>
        <PageContainer>
          <StoresList stores={stores} />
        </PageContainer>
      </main>
    </>
  );
};

export default StoresRegionPage;

export const query = graphql`
  query RegionPageQuery($region: String!, $id: String!) {
    stores: allContentfulSalesPointStore(
      filter: {
        region: { name: { eq: $region } }
        node_locale: { eq: "nl-NL" }
      }
      sort: { fields: storeName, order: ASC }
    ) {
      edges {
        node {
          ...SalesPointStoreFragment
        }
      }
    }

    region: contentfulSalesPointRegion(id: { eq: $id }) {
      ...SalesPointRegionFragment
    }
  }
`;
