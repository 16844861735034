import styled from 'styled-components';
import Typography from '../../typography';

export const Wrapper = styled.div`
  margin: 3.75rem 0;
`;

export const List = styled.ul`
  padding: 0;
  list-style: none;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.25rem;
  }
`;

export const Item = styled.li`
  margin-bottom: 1.25rem;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    flex: 1 1;
    padding: 0 1.25rem;
    min-width: 50%;
    max-width: 50%;
  }
`;

export const Card = styled.div`
  position: relative;
  padding: 2.5rem 2rem;
  border: solid 1px ${({ theme }) => theme.colors.greyLighter};
  border-radius: 8px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }
`;

export const CardTitle = styled.h3`
  ${Typography.h6}
  margin: 0 0 1rem;
  padding-right: 7rem;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    min-width: 100%;
  }
`;

export const CardInfo = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    flex: 1 1 auto;
    width: 50%;
  }

  p {
    margin: 0;
  }

  a svg {
    margin-top: -0.1rem;
    margin-right: 0.5rem;
  }
`;

export const CardActions = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    flex: 1 1 auto;
    width: 50%;
  }
`;

export const CardLabel = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  padding: 0.25rem 1rem 0;
  line-height: 2rem;
  border-radius: 2rem;
  color: white;
  background-color: ${({ theme }) => theme.colors.brand};
`;

export const CardAction = styled.a`
  display: flex;
  align-items: center;
  line-height: 1.75rem;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    line-height: 2rem;
  }

  &,
  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: ${({ theme }) => theme.colors.brand};
  }

  svg {
    margin-right: 1rem;
    fill: ${({ theme }) => theme.colors.brand};
  }
`;
