import React from 'react';
import * as Styles from './styles';

const StoresList = ({ stores }) => {
  const mapsUrl = (str) => {
    const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaaeeeeiiiioooouuuunc------';

    const baseUrl = `https://www.google.com/maps/dir//`;

    let result = str.replace(/^\s+|\s+$/g, '');
    result = result.toLowerCase();

    for (let i = 0, l = from.length; i < l; i += 1) {
      result = result.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    return `${baseUrl}${encodeURIComponent(result)}`;
  };

  return (
    <Styles.Wrapper>
      {stores.length && (
        <Styles.List>
          {stores.map(
            ({
              id,
              storeName,
              street,
              postalCode,
              city,
              phoneNumber,
              mail,
              webOnly,
              websiteUrl,
            }) => (
              <Styles.Item key={id}>
                <Styles.Card>
                  {webOnly ? <Styles.CardLabel>Web only</Styles.CardLabel> : ''}

                  <Styles.CardTitle>{storeName}</Styles.CardTitle>

                  <Styles.CardInfo>
                    {street && <p>{street}</p>}

                    {postalCode && city && (
                      <p>
                        {postalCode} {city}
                      </p>
                    )}

                    {phoneNumber && (
                      <Styles.CardAction href={`tel: ${phoneNumber}`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="black"
                          width="18px"
                          height="18px"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
                        </svg>

                        {phoneNumber}
                      </Styles.CardAction>
                    )}
                  </Styles.CardInfo>

                  <Styles.CardActions>
                    {mail && (
                      <Styles.CardAction href={`mailto: ${mail}`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="black"
                          width="18px"
                          height="18px"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                        </svg>
                        Stuur een e-mail
                      </Styles.CardAction>
                    )}

                    {websiteUrl && (
                      <Styles.CardAction
                        href={websiteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="black"
                          width="18px"
                          height="18px"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" />
                        </svg>
                        Bezoek website
                      </Styles.CardAction>
                    )}

                    {!webOnly ? (
                      <Styles.CardAction
                        href={mapsUrl(`${street} ${postalCode} ${city}`)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="black"
                          width="18px"
                          height="18px"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
                        </svg>
                        Route
                      </Styles.CardAction>
                    ) : (
                      ''
                    )}
                  </Styles.CardActions>
                </Styles.Card>
              </Styles.Item>
            )
          )}
        </Styles.List>
      )}
    </Styles.Wrapper>
  );
};

export default StoresList;
